'use client';

import { Slot } from '@radix-ui/react-slot';
import cx from 'classnames';
import { useBreakpoint } from 'light-react-grid';

import { type Media } from '~/v1/_types/Media';
import { Column } from '~/v1/components/grid/column/column';
import { Grid } from '~/v1/components/grid/grid';
import { Image } from '~/v1/components/image/image';
import { useAspectRatio } from '~/v1/components/image/image.hook';
import { ImageBreakpoint, ImageOrientation } from '~/v1/components/image/image.interface';
import { Breakpoint } from '~/v1/constants/breakpoint';
import { CreditSection, type Credits } from '~/v1/modules/header/creditSection';
import { Preheader } from '~/v1/modules/header/preheader';
import { Breadcrumb } from '~/v1/system/breadcrumb';

import styles from './header.module.scss';

export interface HeaderModuleProps {
  title: string;
  description?: string;
  eyebrow?: string;
  quickLinks?: React.ReactNode;
  credits?: Credits;
  rawDateCredit?: boolean;
  preheaderAction?: React.ReactNode;
  image?: Media;
  /**
   * Determines the amount of spacing after the header
   * @default false
   * @example
   *  /events
   *  /news
   */
  isLandingPage?: boolean;
  /**
   * Determines the size of the title
   * @default false
   * @example
   *  /history
   *  /mission
   */
  isRootPage?: boolean;
}

export function HeaderModule({
  title,
  description,
  eyebrow,
  quickLinks,
  credits,
  rawDateCredit,
  preheaderAction,
  image,
  isLandingPage = false,
  isRootPage = false,
}: HeaderModuleProps) {
  const breakpoint = useBreakpoint();
  const [aspectRatio, setAspectRatio] = useAspectRatio(image);
  const hasPortraitImage = aspectRatio?.orientation === ImageOrientation.PORTRAIT;

  const titleClassName = isRootPage ? 'pageTitleLargeTypography' : 'pageTitleSmallTypography';
  const topTextClass = eyebrow ? 'bodyLargeTypography' : titleClassName;

  const imageProps = hasPortraitImage
    ? {
        colSpan: {
          [ImageBreakpoint.SM]: 5,
          [ImageBreakpoint.MD]: 4,
          [ImageBreakpoint.LG]: 5,
        },
        aspectRatio: '4/5',
      }
    : {
        colSpan: {
          [ImageBreakpoint.SM]: 5,
          [ImageBreakpoint.MD]: 7,
          [ImageBreakpoint.LG]: 9,
        },
        aspectRatio: '3/2',
      };

  return (
    <Grid>
      <header
        className={cx(styles.root, topTextClass, {
          [styles.isLandingPage]: isLandingPage,
          [styles.portraitImage]: hasPortraitImage,
        })}
      >
        <Preheader left={<Breadcrumb />} right={preheaderAction} className={styles.preheader} />
        <div className={styles.spacer} />
        {eyebrow && <div className={cx(styles.eyebrow, 'bodyLargeTypography')}>{eyebrow}</div>}
        <div className={styles.titleAndDescription}>
          <h1 className={titleClassName}>{title}</h1>
          {description && (
            <p className={cx(styles.description, 'modulesTitleTypography')}>{description}</p>
          )}
        </div>
        {quickLinks && <Slot className={cx(styles.quickLinks, 'overflowRight')}>{quickLinks}</Slot>}
        {credits && (
          <CreditSection
            credits={credits}
            rawDate={rawDateCredit}
            className={styles.creditSection}
          />
        )}
        {image && (
          <div
            className={cx(styles.image, 'overflowRight', {
              overflowLeft: !hasPortraitImage || breakpoint === Breakpoint.SM,
            })}
          >
            <Image
              src={image.src}
              alt={String(image.title)}
              {...imageProps}
              onAspectRatio={setAspectRatio}
            />
            {image.description && (
              <Column
                offsetLeft={{
                  lg: hasPortraitImage ? 0 : 1,
                }}
                md={hasPortraitImage ? undefined : 5}
                lg={hasPortraitImage ? undefined : 5}
                className={styles.caption}
              >
                <span className="captionTypography">{image.description}</span>
              </Column>
            )}
          </div>
        )}
      </header>
    </Grid>
  );
}
