import cx from 'classnames';

import { type Faq } from '~/v1/_types/Faq';
import { AccordionText } from '~/v1/components/accordion/text/text';
import { Column } from '~/v1/components/grid/column/column';
import { Grid } from '~/v1/components/grid/grid';

interface AccordionTextModuleProps {
  className?: string;
  title: string;
  items: Faq[];
}

export const AccordionTextModule: React.FC<AccordionTextModuleProps> = ({
  className,
  title,
  items,
}) => {
  return (
    <Grid className={cx('section', className)}>
      <Column sm={5} md={7} lg={7} offsetLeft={{ lg: 1 }}>
        <AccordionText title={title} items={items} />
      </Column>
    </Grid>
  );
};
